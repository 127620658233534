<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm9>
        <div class="overlay attendance">
          <v-card class="primary--border" outlined>
            <v-card-title
              style="border-bottom:1px solid #ccc"
              class="title mb-3"
            >
              Group SMS
              <v-spacer></v-spacer>
            </v-card-title>

            <v-card-text class="pb-1 mt-5">
              <v-form
                ref="form"
                @submit.prevent="save"
                @keydown.native="form.errors.clear($event.target.name)"
                @keyup.enter="save"
                lazy-validation
              >
                <v-flex xs12>
                  <v-autocomplete
                    outlined
                    dense
                    :items="options"
                    v-model="form.option"
                    label="Select Options"
                    required
                  />
                </v-flex>
                <v-flex xs12 v-if="form.option === 'group'">
                  <v-autocomplete
                    outlined
                    dense
                    :items="groups"
                    v-model="group"
                    label="Select Group"
                    multiple
                    required
                  />
                </v-flex>
                <v-flex xs12 v-if="form.option === 'staff'">
                  <v-select
                    outlined
                    dense
                    v-model="role"
                    :items="roles"
                    label="Select Roles"
                    multiple
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="toggle">
                        <v-list-item-action>
                          <v-icon
                            :color="role.length > 0 ? 'indigo darken-4' : ''"
                          >
                            {{ icon }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Select All</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-flex>

                <div v-if="form.option === 'guardian'">
                  <v-flex xs12>
                    <v-select
                      :disabled="gradeLoading"
                      :loading="gradeLoading"
                      v-model="grade"
                      :items="grades"
                      label="Select Grades"
                      multiple
                      outlined
                      dense
                    >
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="toggleGrade">
                          <v-list-item-action>
                            <v-icon
                              :color="grade.length > 0 ? 'indigo darken-4' : ''"
                            >
                              {{ iconGrade }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Select All</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                    </v-select>
                  </v-flex>
                </div>
                <v-flex xs12>
                  <v-select
                    outlined
                    dense
                    :items="templates"
                    class="pa-0"
                    label="Select Template"
                    v-model="template"
                  />
                  <div v-if="Object.keys(sms_stats).length > 0">
                    <v-sms-input-field
                      v-model="form.message"
                      :hide-balance="true"
                      :show-alert="true"
                      :balance-stats="sms_stats"
                      :default-placeholder="[]"
                      :default-sms="form.message"
                    ></v-sms-input-field>
                  </div>
                </v-flex>
              </v-form>
            </v-card-text>
            <v-card-actions v-if="smsServiceStatus">
              <v-spacer></v-spacer>
              <v-btn
                :loading="sending"
                block
                color="success"
                outlined
                @click="save"
                :disabled="btnDisable"
                >{{ btnText }}
              </v-btn>
            </v-card-actions>
          </v-card>

          <div
            :class="
              'inner-overlay ' + (rate_per_sms > balance ? '' : 'dont-show')
            "
          >
            <p>
              <v-icon style="font-size: 50px;color:red;">warning</v-icon>
              <br />
              Insufficient Balance. <br />
              <span style="font-size: 15px;">
                Looks like you less that minimum balance required to send
                SMS.</span
              >
            </p>
          </div>
        </div>
      </v-flex>

      <v-flex xs12 sm3 v-if="smsServiceStatus">
        <v-card class="primary--border elevation-0" outlined>
          <v-card-text class="pb-1">
            <v-data-table
              :loading="fetchingStats"
              hide-default-footer
              :items="[
                { text: 'Balance' },
                { text: 'Rate Per SMS' },
                { text: 'In Queue' },
              ]"
              :headers="[
                ...[
                  { text: 'Head', align: 'left', sortable: false },
                  { text: '#', align: 'right', sortable: false },
                ],
              ]"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <th>{{ item.text }}</th>
                  <td class="text-right">
                    <span v-if="item.text === 'Balance'">{{
                      balance.numberFormat()
                    }}</span>
                    <span v-else-if="item.text === 'Rate Per SMS'">{{
                      rate_per_sms.numberFormat()
                    }}</span>
                    <span v-else-if="item.text === 'In Queue'">{{
                      sms_queue
                    }}</span>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <br />
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs12 sm4 v-else>
        <v-card>
          <v-card-title class="title">
            <v-icon>layers</v-icon> &nbsp;SMS Service is not Activated
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text class="pb-1">
            <p>Please contact our support for activation SMS Service.</p>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import SMS from "@/library/SMS";

export default {
  data: () => ({
    template: "",
    fetchingStats: false,
    sms_stats: {},

    form: new Form(
      {
        sms_type: "g",
        msg_type: "",
        message: "",
        option: "",
        grade: "",
        role: "",
        sms_length: "",
      },
      "/api/sms/group-sms"
    ),
    templates: [],
    sms: new SMS(),
    characterUsed: 0,
    partsUsed: 0,
    btnDisable: false,
    mobileValidate: false,
    btnText: "Send Group SMS",
    balance: 0,
    sms_queue: 0,
    rate_per_sms: 0,
    grades: [],
    grade: [],
    groups: [],
    group: null,
    sections: [],
    section: null,
    smsServiceStatus: false,
    options: [
      { text: "All", value: "all" },
      { text: "Staff", value: "staff" },
      { text: "Guardian", value: "guardian" },
    ],
    roles: [],
    query: null,
    smsDetails: {},
    kaslai: "all",
    role: [],
    gradeLoading: false,
    sending: false,
  }),

  computed: {
    ...mapState(["batch"]),

    likesAllRole() {
      return this.role.length === this.roles.length;
    },
    likesSomeRole() {
      return this.role.length > 0 && !this.likesAllRole;
    },
    icon() {
      if (this.likesAllRole) return "check_box";
      if (this.likesSomeRole) return "indeterminate_check_box";
      return "add_box";
    },

    likesAllGrade() {
      return this.grade.length === this.grades.length;
    },
    likesSomeGrade() {
      return this.grade.length > 0 && !this.likesAllGrade;
    },
    iconGrade() {
      if (this.likesAllGrade) return "check_box";
      if (this.likesSomeGrade) return "indeterminate_check_box";
      return "add_box";
    },
  },

  watch: {
    "form.option": {
      handler(value) {
        if (value === "group") {
          if (!this.groups.length) this.getGroups();
          this.group = null;
        } else if (value === "staff") {
          if (!this.roles.length) this.getRoles();
          this.role = [];
        } else if (value === "guardian") {
          this.getGrades();
          this.grade = [];
        } else {
        }
      },
      deep: true,
    },
    template: function(v) {
      if (v) {
        let d = {};
        d = this.templates.find((res) => res.value === v);
        if (d.text) this.btnDisable = false;
        this.form.message = d.message;
      }
    },
    "form.message": function(value) {
      this.btnDisable = !value;
      if (value && value.length) {
        const smsData = this.sms.getCount(value);
        this.form.sms_length = smsData.numberOfSMS;
        this.form.msg_type = smsData.msg_type;
        this.smsDetails = smsData;
      } else {
        this.smsDetails = {};
      }
    },
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllRole) {
          this.role = [];
        } else {
          this.role = this.roles.slice();
        }
      });
    },
    toggleGrade() {
      this.$nextTick(() => {
        if (this.likesAllGrade) {
          this.grade = [];
        } else {
          this.grade = this.grades.slice();
        }
      });
    },
    queryString() {
      let json = this.pagination;
      return (
        "?" +
        Object.keys(json)
          .map(function(key) {
            if (![null, undefined].includes(json[key]))
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
              );
          })
          .join("&")
      );
    },
    get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
      });
    },

    save() {
      let roleData = [];
      if (this.role.length > 0) {
        this.role.map((res) => {
          roleData.push(res);
        });
      }
      if (this.form.option === "guardian") {
        this.form.role = "guardian";
      } else {
        this.form.role = roleData.join(",");
      }
      this.form.grade = this.grade.toString();
      this.form.fireFetch = false;
      this.btnDisable = true;
      this.sending = true;

      this.btnText = "Processing";
      this.form
        .store()
        .then((res) => {})
        .catch((e) => {
          this.$events.fire("notification", {
            message: e.data.message,
            status: "error",
          });
        })
        .finally((e) => {
          this.btnDisable = false;
          (this.template = ""), (this.btnText = "Send Group SMS");
          this.sending = false;
          this.getBalance();
        });
    },
    getTemplates() {
      this.$rest.get("/api/sms/template").then((data) => {
        this.templates = data.data.data.map((res) => {
          return { value: res.id, text: res.title, message: res.message };
        });
      });
    },
    mobileChange() {
      if (this.form.mobile.length > 10) {
        this.form.mobile = this.form.mobile.substr(0, 10);
        this.mobileValidate = true;
      } else {
        this.mobileValidate = false;
      }
    },
    getBalance() {
      this.fetchingStats = true;

      this.$rest
        .get("/api/sms/dashboard")
        .then((res) => {
          this.balance = res.data.balance;
          this.sms_queue = res.data.smsqueue;
          this.rate_per_sms = res.data.rate_per_sms;
          this.sms_stats = res.data;
        })
        .finally(() => {
          this.fetchingStats = false;
        });
    },
    getGrades() {
      this.gradeLoading = true;
      this.$rest
        .get("/api/grades?descending=false&slim=true&rowsPerPage=20")
        .then((data) => {
          this.grades = data.data.data.map((res) => {
            return { text: res.name, value: res.id };
          });
        })
        .finally(() => {
          this.gradeLoading = false;
        });
    },

    getRoles() {
      this.$rest.get("/api/roles?rowsPerPage=100").then((data) => {
        let rolesData = data.data.data.map((item) => {
          return { value: item.id, text: item.name, slug: item.slug };
        });
        this.roles = rolesData.filter(
          (item) => !["guardian", "student"].includes(item.slug)
        );
      });
    },
  },
  mounted() {
    this.smsServiceStatus = true;
    // this.$auth.user().roles.filter(res=> {
    //     if(res.slug === 'sms-user') this.smsServiceStatus = true;
    // });
    this.getTemplates();
    this.getBalance();
  },
};
</script>
<style lang="scss" scoped>
.mobile-validation-message {
  font-size: 11px;
  margin-top: -17px;
}
</style>
